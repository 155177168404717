import React from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import { useUserContext } from "../../../store/context/User";
import { usePermissions } from "../../../hooks/usePermissions";

const AppNav = () => {
	const { fullName } = useUserContext();
	const storePermissions = usePermissions("MANAGE_STORE");
	const userPermissions = usePermissions("MANAGE_USER");
	const productPermissions = usePermissions("MANAGE_PRODUCT");
	const orderPermissions = usePermissions("MANAGE_ORDER");
	const orderPrintPermissions = usePermissions("MANAGE_ORDER", true);
	return (
		<Navbar bg='dark' variant='dark' expand='lg'>
			<Container>
				<Link to='dashboard' className='navbar-brand'>
					MD Dukin
				</Link>
				<Navbar.Toggle aria-controls='basic-navbar-nav' />
				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='me-auto'>
						{
							storePermissions.includes('VIEW') && (
								<NavLink to='stores' className='nav-link'>
									Store
								</NavLink>
							)
						}
						{
							userPermissions.includes('VIEW') && (
								<NavLink to='users' className='nav-link'>
									Users
								</NavLink>
							)
						}
						{
							productPermissions.includes('VIEW') && (
								<NavLink to='products' className='nav-link'>
									Products
								</NavLink>	
							)
						}
						{
							orderPermissions.includes('VIEW') && (
								<NavLink to='orders' className='nav-link'>
									Orders
								</NavLink>	
							)
						}
						{
							<NavLink to='orders/waste' className='nav-link'>
								Waste
							</NavLink>
						}
						{
							orderPrintPermissions.includes('VIEW_ALL') && (
								<>
									<NavLink to='orders/print-order' className='nav-link'>
										Print Order
									</NavLink>
									<NavLink to='orders/account' className='nav-link'>
										Account
									</NavLink>
								</>
							)
						}
					</Nav>
				</Navbar.Collapse>
				<Navbar.Collapse className='justify-content-end'>
					<NavLink to='products' className='nav-link'></NavLink>
					<Nav className='mr-auto'>
						<NavDropdown
							title={fullName}
							id='basic-nav-dropdown'
						>
							<Link to='/auth/logout' className='dropdown-item'>
								Logout
							</Link>
						</NavDropdown>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

export default AppNav;
