import React, { useState } from "react";
import { Button, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import FormikControl from "../../../components/formik/FormikControl";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../../services/auth";
import { toast } from "react-toastify";

const initialValues = {
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("Enter valid email").required("Email Requied!"),
});

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const { messages } = await forgotPassword(values);
      toast.info(messages[0]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleSingIn = () => {
    navigate("/auth/login");
  };

  return (
    <>
      <Col className="text-center mb-3">
        <h2>Forgot Password</h2>
      </Col>
      <Col
        xs={12}
        sm={10}
        md={8}
        lg={6}
        xl={4}
        xxl={4}
        className="border p-3 rounded app-layout"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <>
              <Form autoComplete="off">
                <FormikControl
                  control="input"
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="Enter Email"
                />

                <div className="d-flex justify-content-between">
                  <Button variant="primary" type="submit" disabled={loading}>
                    {loading ? "Please wait..." : "Submit"}
                  </Button>
                  <Button
                    variant="default"
                    type="button"
                    onClick={handleSingIn}
                  >
                    Back to Sing In
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </Col>
    </>
  );
};

export default ForgotPassword;
