import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AUTH_ACTIONS } from "../../../store/slices/auth";

const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;
const AUTH_PERMISSIONS = process.env.REACT_APP_AUTH_PERMISSIONS;

const Logout = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { setToken } = AUTH_ACTIONS;

	useEffect(() => {
		const removeAuth = async () => {
			await localStorage.removeItem(AUTH_TOKEN);
			await localStorage.removeItem(AUTH_PERMISSIONS);
			dispatch(setToken(null));
			navigate("/auth");
		};
		removeAuth();
	}, []);

	return <div>Logout</div>;
};

export default Logout;
