import { ErrorMessage, Field } from "formik"
import { FormGroup, FormLabel } from "react-bootstrap";
import TextError from './TextError'

const Checkbox = (props) => {
	const {label, name, ...rest} = props
	return (
		<div className="mb-3">
			<div className="form-check">
				<Field type="checkbox" name={name} {...rest} className="form-check-input" />
				<label
					className='form-check-label'
					htmlFor={name}
				>
					{label}
				</label>
			</div>
			<ErrorMessage name={name} component={TextError} />
		</div>
	)
};

export default Checkbox;
