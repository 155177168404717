import React from 'react'

const TextError = ({children}) => {
  return (
	<div className='text-danger mt-1'>
		{children}
	</div>
  )
}

export default TextError