import TextInput from "./TextInput"
import RadioButtons from "./RadioButtons"
import Select from "./Select"
import CheckboxGroup from "./CheckboxGroup"
import Checkbox from "./Checkbox"
import Textarea from "./Textarea"
import Datepicker from "./Datepicker"

const FormikControl = (props) => {
	const {control, ...rest} = props
	switch(control) {
		case 'input':
			return <TextInput {...rest} />
		case 'textarea':
			return <Textarea {...rest} />
		case 'select':
			return <Select {...rest} />
		case 'radio':
			return <RadioButtons {...rest} />
		case 'checkbox':
			return <CheckboxGroup {...rest} />
		case 'singleCheckBox':
			return <Checkbox {...rest} />
		case 'date':
			return <Datepicker {...rest} />
		default:
			return null;
	}
}

export default FormikControl