import http from './http'

const getUnits = () => {
    return http({
        method: 'get',
        url: '/v1/units'
    })
}

export {
    getUnits
}