import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import FormikControl from "../../../components/formik/FormikControl";
import { addStore, editStore, getStoreById } from "../../../services/store";
import { states } from "../../../data/states";

const initialValues = {
  id: 0,
  name: "",
  code: "",
  address: {
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  },
  priority: 0,
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name Required!"),
  code: Yup.string().required("Store Prefix Required!"),
  address: Yup.object({
    address1: Yup.string().required("Address Line 1 Required!"),
    city: Yup.string().required("City Required!"),
    state: Yup.string()
      .required("State Required!")
      .min(2, "Allow only State Codes!")
      .max(2, "Allow only State Codes!"),
    zip: Yup.string()
      .required("Zipcode Required!")
      .min(5, "Zipcode must have 5 Digits!")
      .max(5, "Zipcode must have 5 Digits!"),
  }),
  priority: Yup.number().required("Priority Required!").min(1, "Priority must be greater than 0!"),
});

const AddEditStore = ({ storeId, onStoreUpdate, onError }) => {
  const [store, setStore] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchStore = async () => {
      const { data, error } = await getStoreById(storeId);
      if (error) {
        toast.error("Error, while loading Store! Please contact Web Admin!");
      } else {
        setStore(data);
      }
    };
    if (storeId > 0) fetchStore();
  }, [storeId]);

  const onSubmit = (values) => {
    setLoading(true);
    values.address.zip.toString();

    if (storeId === 0) {
      handleAddStore(values);
      delete values.id;
    } else {
      handleEditStore(values);
    }
  };

  const handleAddStore = async (values) => {
    delete values.id;
    values.priority = +values.priority;
    try {
      const { data, error, messages } = await addStore(values);
      if (!error) {
        // success
        toast.success(`Store created successfully!`);
        onStoreUpdate(data);
      } else {
        toast.error(messages.join(","));
        onError();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleEditStore = async (values) => {
    delete values.id;
    values.priority = +values.priority;
    try {
      const { data, error, messages } = await editStore(storeId, values);
      if (!error) {
        // success
        toast.success(`Store updated successfully!`);
        onStoreUpdate(data);
      } else {
        toast.error(messages.join(","));
        onError();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={store}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <>
            <Form autoComplete="off">
              <FormikControl
                control="input"
                type="text"
                name="name"
                label="Name"
                placeholder="Enter Name"
              />
              <FormikControl
                control="input"
                type="text"
                name="code"
                label="Prefix"
                placeholder="Enter Store Prefix"
              />
              <FormikControl
                control="input"
                type="text"
                name="address.address1"
                label="Address Line 1"
                placeholder="Enter Address Line 1"
              />
              <FormikControl
                control="input"
                type="text"
                name="address.address2"
                label="Address Line 2"
                placeholder="Enter Address Line 2"
              />
              <FormikControl
                control="input"
                type="text"
                name="address.city"
                label="City"
                placeholder="Enter City"
              />
              <FormikControl
                control="select"
                name="address.state"
                label="State"
                options={states}
              />
              <FormikControl
                control="input"
                type="text"
                name="address.zip"
                label="Zipcode"
                placeholder="Enter Zipcode"
              />
              <FormikControl
                control="input"
                type="text"
                name="priority"
                label="Priority"
                placeholder="Enter Priority"
              />

              <div className="d-flex justify-content-between">
                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? "Please wait..." : "Submit"}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default AddEditStore;
