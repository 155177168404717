import http from "./http";

const login = (data) => {
	return http({
		method: 'post',
		url: '/v1/auth/login',
		headers: {
			"public-request": "true",
		},
		data
	})
};

const logout = (data) => {
	return http({
		method: 'post',
		url: '/v1/auth/logout',
	})
}

const forgotPassword = (data) => {
	return http({
		method: 'post',
		url: '/v1/auth/forgotpassword',
		headers: {
			"public-request": "true",
		},
		data
	})
}

const resetPassword = (data) => {
	return http({
		method: 'post',
		url: '/v1/auth/resetpassword',
		headers: {
			"public-request": "true",
		},
		data
	})
}

export {
	login,
	logout,
	forgotPassword,
	resetPassword
};
