import http from './http'

const whoami = () => {
	return http({
		method: "get",
		url: "/v1/whoami",
	});
}

const getUsers = () => {
    return http({
        method: 'get',
        url: '/v1/users'
    })
}

const getUserById = (id) => {
    return http({
        method: 'get',
        url: `/v1/users/${id}`
    })
}

const addUser = (values) => {
    return http({
        method: 'post',
        url: '/v1/users',
        data: values
    })
}

const editUser = (id, values) => {
    return http({
        method: 'put',
        url: `/v1/users/${id}`,
        data: values
    })
}

export {
	whoami,
	getUsers,
	getUserById,
	addUser,
	editUser
}