import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsFillPencilFill, BsFillArchiveFill } from "react-icons/bs";
import { Button, Tabs, Tab, Form } from "react-bootstrap";
import moment from "moment";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import * as dayjs from "dayjs";
import classnames from "classnames";

import Loading from "../../../components/ui/Loading";
import { usePermissions } from "../../../hooks/usePermissions";
import { getOrders } from "../../../services/order";
import AppTable from "../../../components/ui/Table";
import { useUserContext } from "../../../store/context/User";
import FormikControl from "../../../components/formik/FormikControl";
import { Formik } from "formik";
import { useSelector } from "react-redux";

function canPlace(userRole, orderDate) {
  if (userRole.toLowerCase() === "admin") return true;

  const today = dayjs();
  const curr = dayjs(orderDate);

  return curr.isAfter(today);
}

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [safetyOrders, setSafetyOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [pageLoading, setPageLoading] = useState(true);
  const { userStores } = useSelector((state) => state.store);

  const maxRows = 30;

  const navigate = useNavigate();
  const orderPermissions = usePermissions("MANAGE_ORDER");
  const { type: userRole } = useUserContext();

  const columns = [
    { path: "store.name", label: "Store" },
    {
      key: "orderDate",
      label: "Order On",
      content: (order) => moment(order.orderDate).format("MM/DD/YYYY"),
    },
    { path: "amount", label: "Total Amount" },
    {
      key: "totalItems",
      label: "Total Items",
      content: (order) => <>{order.orderDetails.length}</>,
    },
    {
      label: "Actions",
      key: "action",
      content: (order) => (
        <>
          {orderPermissions.includes("MODIFY") && (
            <>
              <Button
                type="button"
                variant="default"
                onClick={() => handleAddEditOrder(order.id, false, false)}
                title="Edit"
                disabled={!canPlace(userRole, order.orderDate)}
                className={classnames("btn-borderless")}
                border="none"
              >
                <BsFillPencilFill
                  title={`${
                    canPlace(userRole, order.orderDate)
                      ? ""
                      : "Cannot edit order"
                  }`}
                />
              </Button>
              <Button
                type="button"
                variant="default"
                onClick={() => handleAddEditOrder(order.id, true, false)}
                title="Add Waste"
              >
                <BsFillArchiveFill />
              </Button>
            </>
          )}
        </>
      ),
    },
  ];

  const safetyOrderColumns = [
    { path: "store.name", label: "Store" },
    {
      key: "orderDate",
      label: "Order On",
      content: (order) => dayjs(order.orderDate).format("dddd"),
    },
    {
      key: "totalItems",
      label: "Total Items",
      content: (order) => <>{order.orderDetails.length}</>,
    },
    {
      label: "Actions",
      key: "action",
      content: (order) => (
        <>
          {orderPermissions.includes("MODIFY") && (
            <>
              <Button
                type="button"
                variant="default"
                onClick={() => handleAddEditOrder(order.id, false, true)}
                title="Edit"
                className={classnames("btn-borderless")}
                border="none"
              >
                <BsFillPencilFill title="Edit Order" />
              </Button>
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const queryParams = `page=1&maxRows=100&preOrder=1&sortby=orderDate:asc`;

        const { data, error, pagination } = await getOrders(queryParams);
        if (!error) {
          setSafetyOrders(data);
        } else {
          alert("show errors");
        }
      } catch (err) {
        console.error("Getting error while fetching products");
      }
    };
    fetchOrders();
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const queryParams = `page=${page}&maxRows=${maxRows}&preOrder=0`;

        const { data, error, pagination } = await getOrders(queryParams);
        if (!error) {
          setOrders(data);
          setPagination(pagination);
          setPageLoading(false);
        } else {
          alert("show errors");
        }
      } catch (err) {
        console.error("Getting error while fetching products");
      }
    };
    setPageLoading(true);
    fetchOrders();
  }, [page]);

  const handleAddEditOrder = (orderId, waste, safetyOrder) => {
    navigate(`/app/orders/${orderId}/${waste}/${safetyOrder}`);
  };

  const pageHandler = ({ target }) => {
    setPage(target.value);
  };

  const onSubmit = async (values, { setSubmitting }) => {
    console.log(values);
    return false;
  }

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const getStoreOptions = (userStores) => {
    const stores = userStores.map((item) => ({
      value: item.id,
      key: item.name,
    }));
    return stores.length === 1
      ? stores
      : [{ key: "Pick a store", value: "" }, ...stores];
  };

  const storeOptions = getStoreOptions(userStores);

  if (!orderPermissions.includes("VIEW")) {
    navigate("/app/dashboard");
  }

  if (pageLoading) {
    return <Loading />;
  }

  return (
    <div className="app-layout">
      <div className="d-flex justify-content-between">
        <div className="ms-2 mb-2">
          <h3>Orders</h3>
        </div>
        {orderPermissions.includes("CREATE") && (
          <div className="me-2 mb-2">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleAddEditOrder(0, false, false)}
            >
              Add Order
            </button>
          </div>
        )}
      </div>

      <Tabs defaultActiveKey="daily" id="mddunkin-orders" className="mb-3">
        <Tab eventKey="daily" title="Daily">
          <AppTable dataSource={orders} columns={columns} />
          <div className="d-flex justify-content-end">
            <Pagination
              size="sm"
              value={page}
              totalPages={pagination.totalPages}
              onChange={pageHandler}
              firstPageValue={1}
              showFirstLast={true}
              showPrevNext={true}
              atBeginEnd={3}
              aroundCurrent={1}
            />
          </div>
        </Tab>
        <Tab eventKey="safety" title="Safety">
          <AppTable dataSource={safetyOrders} columns={safetyOrderColumns} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Orders;
