import { ErrorMessage, Field } from "formik";
import DateView from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import TextError from "./TextError";

const Datepicker = (props) => {
  const { label, name, onDateChange, ...rest } = props;

  return (
    <div className="mb-3">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <DateView
              id={name}
              {...field}
              {...rest}
              selected={value}
              onChange={(val) => {
                setFieldValue(name, val);
				if(onDateChange) {
					onDateChange(val);
				}
              }}
              className="form-control"
              showPopperArrow={false}
              dateFormat="MM/dd/yyyy"
            />
          );
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default Datepicker;
