import { createSlice } from "@reduxjs/toolkit";

const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;
const AUTH_PERMISSIONS = process.env.REACT_APP_AUTH_PERMISSIONS;

const initialState = {
  token: localStorage.getItem(AUTH_TOKEN),
  permissions: localStorage.getItem(AUTH_PERMISSIONS)
    ? JSON.parse(localStorage.getItem(AUTH_PERMISSIONS))
    : null,
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setPermissions: (state, { payload }) => {
      state.permissions = payload;
    },
  },
});

export const AUTH_ACTIONS = auth.actions;
export default auth;
