import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const UserContext = createContext({
	firstName: "",
	lastName: "",
	fullName: "",
});

export const useUserContext = () => {
	return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
	const [user, setUser] = useState({});
	const { permissions } = useSelector((state) => state.auth);

	useEffect(() => {
		const loggedInUser = {
			firstName: permissions.firstName,
			lastName: permissions.lastName,
			fullName: `${permissions.firstName} ${permissions.lastName}`,
			type: permissions.type,
		};
		setUser(loggedInUser);
	}, []);

	return (
		<UserContext.Provider value={{ ...user }}>
			{children}
		</UserContext.Provider>
	);
};
