import React, { Fragment, useState } from "react";
import { Button, Row, Col, Form, Table } from "react-bootstrap";
import DateView from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getWasteReport } from "../../../services/order";

const { Select } = Form;

const avgOptions = [
  { key: "Pick a option", value: "" },
  { key: "Yearly", value: "Yearly" },
  { key: "Monthly", value: "Monthly" },
  { key: "Weekly", value: "Weekly" },
];

const WasteItem = () => {
  const { userStores } = useSelector((state) => state.store);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [store, setStore] = useState("");
  const [avg, setAvg] = useState("");
  const [orders, setOrders] = useState({});
  const [loading, setLoading] = useState(false);

  const getStoreOptions = (userStores) => {
    const stores = userStores.map((item) => ({
      value: item.id,
      key: item.name,
    }));
    return stores.length === 1
      ? stores
      : [{ key: "Pick a store", value: "" }, ...stores];
  };

  const storeOptions = getStoreOptions(userStores);

  const handleExport = async () => {
    if (!store) {
      toast.error("Please select a store");
      return false;
    }
    if (!avg) {
      toast.error("Please select an option");
      return false;
    }
    if (!startDate) {
      toast.error("Please select start date");
      return false;
    }
    if (!endDate) {
      toast.error("Please select end date");
      return false;
    }

    const payload = {
      store,
      avg,
      orderStartDate: moment(startDate).format("YYYY-MM-DD"),
      orderEndDate: moment(endDate).format("YYYY-MM-DD"),
    };
    
    setLoading(true);
    const { data, error } = await getWasteReport(payload);

    if(error) {
      setLoading(false);
      toast.error("Something went wrong!");
      return false;
    }

    const orders = data.reduce((acc, item) => {
      let key = `${item.year}`
      if(avg === 'Monthly') {
          key += `-${item.month}`
      } else if (avg === 'Weekly') {
          key += `-${item.week}`
      }      

      if(!acc[key]) {
        acc[key] = {
          label: {
            year: item.year,
            month: item?.month,
            week: item?.week
          },
          data: []
        };
      }

      acc[key]['data'].push(item);

      return acc;
    }, {});    
    setOrders(orders);
    setLoading(false);
  };

  return (
    <div className="app-layout">
      <Row>
        <Col xs={12} sm={6} md={3}>
          <label htmlFor={store} className="form-label">
            Pick a Store
          </label>
          <Select onChange={(e) => setStore(e.target.value)}>
            {storeOptions.map((option) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.key}
                </option>
              );
            })}
          </Select>
        </Col>
        <Col xs={12} sm={6} md={3}>
          <label htmlFor={avg} className="form-label">
            Pick an Option
          </label>
          <Select onChange={(e) => setAvg(e.target.value)}>
            {avgOptions.map((option) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.key}
                </option>
              );
            })}
          </Select>
        </Col>
        <Col xs={12} sm={6} md={3}>
          <label htmlFor={startDate} className="form-label">
            Order Start Date
          </label>
          <DateView
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="form-control col-md-2"
            placeholderText="Select Start Date"
            dateFormat="MM/dd/yyyy"
          />
        </Col>
        <Col xs={12} sm={6} md={3}>
          <label htmlFor={endDate} className="form-label">
            Order End Date
          </label>
          <DateView
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className="form-control col-md-2"
            placeholderText="Select End Date"
            dateFormat="MM/dd/yyyy"
          />
        </Col>
        <Col xs={12} sm={6} md={3} className="mt-3">
          <Button type="button" onClick={handleExport} disabled={loading}>
            {loading ? "Please wait..." : "Submit"}
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
          <Table bordered>
            <thead>
              <tr>
                <th>Name</th>
                <th>Quantity</th>
                <th>Waste</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(orders).length === 0 && <tr><td colSpan="3">No data found</td></tr>}
              {
                Object.keys(orders).map((key) => {
                  return (                  
                    <Fragment key={key}>
                      <tr>
                        <td colSpan="3" style={{backgroundColor: '#efefef'}}>
                          <DisplayLabel label={orders[key].label} />
                        </td>
                      </tr>
                      <DataDisplay data={orders[key].data} />
                    </Fragment>
                  )
                })
              }
            </tbody>
          </Table>
      </Row>
    </div>
  );
};

export default WasteItem;

function DataDisplay({ data }) {
  return (
    <>
      {
        data.map((item) => {
          return (
            <tr key={item.id+item.name}>
              <td>{item.name}</td>
              <td>{item.quantity.toFixed(2)}</td>
              <td>{item.wasteQuantity.toFixed(2)}</td>
            </tr>
          )
        })
      }
    </>
  )
}

function DisplayLabel({ label }) {
  return (
    <>
      {label.year ? <span>Year {label.year} </span> : ''}
      {label.month ? <span> Month {label.month} </span> : ''} 
      {label.week ? <span> Week {label.week} </span> : ''} 
    </>  
  )
}