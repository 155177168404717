import React, { useEffect, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AUTH_ACTIONS } from "../../../store/slices/auth";
import { STORE_ACTIONS } from "../../../store/slices/store";
import FormikControl from "../../../components/formik/FormikControl";
import { login } from "../../../services/auth";
import { whoami } from "../../../services/user";
import { getStores } from "../../../services/store";

const initialValues = {
	email: "",
	password: "",
};

const validationSchema = Yup.object({
	email: Yup.string().email("Enter valid email").required("Email Requied!"),
	password: Yup.string().required("Password Required!"),
});

const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;
const AUTH_PERMISSIONS = process.env.REACT_APP_AUTH_PERMISSIONS;
const USER_STORES = process.env.REACT_APP_USER_STORES;

const Login = () => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { token } = useSelector((state) => state.auth);
	const { setToken, setPermissions } = AUTH_ACTIONS;
	const { setUserStores } = STORE_ACTIONS;

	useEffect(() => {
		if (token) {
			navigate("/app");
		}
	}, [token]);

	const onSubmit = async (values, { setSubmitting }) => {
		try {
			setLoading(true);
			const {
				data: { token },
			} = await login(values);

			await localStorage.setItem(AUTH_TOKEN, token);

			const { data: userPermissions } = await whoami();
			await localStorage.setItem(
				AUTH_PERMISSIONS,
				JSON.stringify(userPermissions)
			);

			const {data: stores} = await getStores();
			await localStorage.setItem(
				USER_STORES,
				JSON.stringify(stores)
			)

			await dispatch(setToken(token));
			await dispatch(setPermissions(userPermissions));
			await dispatch(setUserStores(stores));
			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};

	const handleForgotPassword = () => {
		navigate('/auth/forgot-password')
	}

	return (
		<>
			<Col className='text-center mb-3'>
				<h2>MD Dunkin Login</h2>
			</Col>
			<Col
				xs={12}
				sm={10}
				md={8}
				lg={6}
				xl={4}
				xxl={4}
				className='border p-3 rounded app-layout'
			>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
				>
					{(formik) => (
						<>
							<Form autoComplete='off'>
								<FormikControl
									control='input'
									type='email'
									name='email'
									label='Email'
									placeholder='Enter Email'
								/>
								<FormikControl
									control='input'
									type='password'
									name='password'
									label='Password'
									placeholder='Enter Password'
								/>

								<div className="d-flex justify-content-between">
									<Button
										variant='primary'
										type='submit'
										disabled={loading}
									>
										{loading ? "Please wait..." : "Submit"}
									</Button>
									<Button variant="default" type="button" onClick={handleForgotPassword}>
										Forgot Password?
									</Button>
								</div>
							</Form>
						</>
					)}
				</Formik>
			</Col>
		</>
	);
};

export default Login;
