import React, { useEffect, useState, useRef } from "react";
import { Button, Row, Col, FormCheck } from "react-bootstrap";
import DateView from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { addPreOrder } from "../../../services/order";

import { getOrders } from "../../../services/order";

const PrintOrder = () => {
  const [orderDate, setOrderDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [safetyOrder, setSafetyOrder] = useState(false);

  const dateEl = useRef();

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const printOrderDate = moment(orderDate).format("YYYY-MM-DD");
      const { data, error } = await getOrders("orderDate=" + printOrderDate);
      //const { data, error } = getOrder();
      if (!error) {
        if (data.length > 0) {
          exportOrder(data, orderDate);
        } else {
          toast.error("No orders found for this date");
        }
      } else {
        toast.error("Something, went wrong!");
      }
    } catch (err) {
      console.log(err);
      console.error("Getting error while fetching products");
    }
    setLoading(false);
  };

  const handleExport = async () => {
    if (orderDate === "") {
      toast.error("Please select order date");
    } else {
      if(safetyOrder) {
        if(window.confirm('Are you sure you want to create a safety order?')) {
          setLoading(true);
          const safetyOrderDate = moment(orderDate).format("YYYY-MM-DD")
          const { data, error, messages } = await addPreOrder({orderDate: safetyOrderDate});
          if(error) {
            toast.error(messages[0]);
            return false;
          }
          toast.info("Safety order created successfully");
          fetchOrders();
        }
      } else {
        fetchOrders();
      }
    }
  };

  return (
    <>
      <Row className="app-layout">
        <Col xs={12} sm={6} md={3}>
          <DateView
            ref={dateEl}
            selected={orderDate}
            onChange={(date) => setOrderDate(date)}
            className="form-control col-md-2"
            placeholderText="Select an order date"
            dateFormat="MM/dd/yyyy"
          />
        </Col>
        <Col xs={12} sm={6} md={3}>
          <FormCheck
            type="checkbox"
            label={`Auto Generate Safety Order`}
            id={`safetyOrder`}
            checked={safetyOrder}
            onChange={(e) => setSafetyOrder(e.target.checked)}
          />
        </Col>
        <Col xs={12} sm={12} md={12} className="mt-3">
          <Button type="button" onClick={handleExport} disabled={loading}>
            {loading ? "Exporting..." : "Export"}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PrintOrder;

const fontSize = 16;

function exportOrder(orders, orderDate) {
  const stores = getStores(orders);
  const products = getProducts(orders);
  const items = getItems(orders);

  const printOrderDate = moment(orderDate).format("YYYY-MM-DD");
  const displayOrderDate = moment(orderDate).format("M-D-YY");
  const dayOfDate = moment(printOrderDate).format("dddd");

  const workbook = new ExcelJS.Workbook();
  workbook.creator = "MD Dunkin";
  workbook.created = new Date();
  workbook.calcProperties.fullCalcOnLoad = true;
  workbook.views = [
    {
      state: "frozen",
      topLeftCell: "A1",
      activeCell: "A1",
    },
  ];

  const worksheet = workbook.addWorksheet("MD Dunkin", {
    pageSetup: {
      paperSize: 9,
      showGridLines: true,
      fitToPage: true,
      fitToWidth: 1,
      fitToHeight: 0,
    },
  });
  const columns = [
    {
      header: `${displayOrderDate} / ${dayOfDate}`,
      key: "id",
      width: 25,
      style: { font: { bold: "true", size: fontSize } },
    },
  ];

  const headerStyle = {
    font: { bold: "true", size: fontSize },
    alignment: { horizontal: "center", vertical: "top", wrapText: "true" },
    numFmt: "0",
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "bebebe" },
    },
  };

  stores.forEach((store) => {
    columns.push({
      header: store.name,
      key: store.name,
      width: 15,
    });
  });

  columns.push({
    header: "Total",
    key: "totalAll",
    width: 15,
  });

  worksheet.columns = [...columns];

  const rows = [];
  const collectProductRows = [];
  const collectItemRows = [];
  const collectTotalRows = [];
  const totalDozen = {};
  stores.forEach((store) => {
    totalDozen[`doz${store.id}`] = 0;
  });

  let rowIndex = 2;
  products.forEach((product) => {
    const row = [];
    // product
    row.push(product.name);
    stores.forEach((store) => {
      row.push("");
    });
    row.push("");
    rows.push(row);
    collectProductRows.push(rowIndex);
    rowIndex++;

    // item [new line]
    product.items.forEach((item) => {
      const row = []; // blank row
      row.push(item.name); // item
      stores.forEach((store) => {
        const itemKey = `item_${store.id}_${product.id}_${item.id}`;
        const qty = items[itemKey] ? items[itemKey].qty : 0;
        item.total += qty;
        row.push(qty); // store quantity;
      });
      row.push(item.total);
      rows.push(row);
      collectItemRows.push(rowIndex);
      rowIndex++;
    });

    const totalRow = []; // new line
    totalRow.push("Total"); // product total [start];
    stores.forEach((store) => {
      const total = product.items.reduce((acc, el) => {
        const itemKey = `item_${store.id}_${product.id}_${el.id}`;
        acc = acc + (items[itemKey]?.tQty || 0);
        return acc;
      }, 0);
      product.total += total;
      totalRow.push(total.toFixed(2));
      if ([10, 9, 6, 5].includes(product.id)) {
        totalDozen[`doz${store.id}`] = totalDozen[`doz${store.id}`] + +total;
      }
    });
    totalRow.push(product.total.toFixed(2)); // product total
    rows.push(totalRow);
    collectTotalRows.push(rowIndex);
    rowIndex++;
  });

  worksheet.addRows(rows);

  worksheet.getRow(1)._cells.forEach((cell, index) => {
    if (index > 0) {
      cell.style = { ...headerStyle };
    }
  });

  for (const collectProductRow of collectProductRows) {
    worksheet.getRow(collectProductRow)._cells.forEach(
      (cell) =>
        (cell.style = {
          font: { bold: "true", size: fontSize },
          fill: {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "f1f1f1" },
          },
        })
    );
  }

  for (const collectTotalRow of collectTotalRows) {
    worksheet.getRow(collectTotalRow)._cells.forEach((cell, index) => {
      if (index === 0) {
        cell.style = { font: { bold: "true", size: fontSize } };
      } else {
        cell.style = {
          font: { bold: "true", size: fontSize },
          alignment: {
            horizontal: "center",
            vertical: "top",
            wrapText: "true",
          },
          fill: {
            type: "pattern",
            pattern: "none",
          },
          numFmt: "0",
        };
        if (index > 0) {
          cell.style["numFmt"] = "##,####0";
        }
      }
    });
  }

  for (const collectItemRow of collectItemRows) {
    worksheet.getRow(collectItemRow)._cells.forEach((cell, index) => {
      if (index === 0) {
        cell.style = {
          font: { bold: false, size: fontSize },
          alignment: {
            horizontal: "left",
            vertical: "top",
            wrapText: "true",
          },
        };
      } else {
        cell.style = {
          font: { bold: false, size: fontSize },
          alignment: {
            horizontal: "center",
            vertical: "top",
            wrapText: "true",
          },
        };
      }
    });
  }

  worksheet.addRows([[]]);
  rowIndex++;

  const dozRow = [];
  let dozTotal = 0;
  dozRow.push("TOTAL DOZEN");
  stores.forEach((store) => {
    const itemTotal = Math.round(totalDozen[`doz${store.id}`]);
    dozTotal += itemTotal;
    dozRow.push(itemTotal);
  });
  dozRow.push(dozTotal);
  worksheet.addRows([dozRow]);

  worksheet.getRow(rowIndex).font = { bold: "true", size: fontSize };
  worksheet.getRow(rowIndex).alignment = {
    horizontal: "center",
    vertical: "top",
    wrapText: "true",
  };

  rowIndex++;

  worksheet.addRows([[]]);
  worksheet.addRows([[]]);
  worksheet.addRows([[]]);
  rowIndex = rowIndex + 3;

  // calculate summary
  const productSummary = getSummary(products);

  productSummary.forEach((item, index) => {
    worksheet.addRows([[...item]]);
    if (index === 0) {
      worksheet.getRow(rowIndex)._cells.forEach((cell, index) => {
        if (index !== 3) {
          cell.style = {
            font: { bold: "true", size: fontSize },
            alignment: {
              horizontal: "center",
              vertical: "top",
              wrapText: "true",
            },
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "bebebe" },
            },
          };
        }
      });
    }
    if (index === 1) {
      worksheet.getRow(rowIndex)._cells.forEach((cell, index) => {
        if (index < 3) {
          cell.style = {
            font: { bold: "true", size: fontSize },
            alignment: {
              horizontal: "center",
              vertical: "top",
              wrapText: "true",
            },
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "f1f1f1" },
            },
          };
        }
      });
    }
    if (index > 1) {
      worksheet.getRow(rowIndex)._cells.forEach((cell, index) => {
        if ([1, 2, 5].includes(index)) {
          cell.style = {
            font: { bold: "true", size: fontSize },
            alignment: {
              horizontal: "center",
              vertical: "top",
              wrapText: "true",
            },
            fill: {
              type: "pattern",
              pattern: "none",
            },
          };
        }
        if (index === 4) {
          cell.style = {
            font: { bold: "true", size: fontSize },
            alignment: {
              horizontal: "left",
              vertical: "top",
              wrapText: "true",
            },
            fill: {
              type: "pattern",
              pattern: "none",
            },
          };
        }
      });
    }
    rowIndex++;
  });

  worksheet.addRows([[]]);
  rowIndex++;

  // frier
  const frierRow = [];
  frierRow.push("");
  frierRow.push("");
  frierRow.push("");
  frierRow.push("");
  frierRow.push("For Frier");
  frierRow.push("");
  frierRow.push("");

  worksheet.addRows([frierRow]);

  worksheet.getRow(rowIndex)._cells.forEach((cell, index) => {
    if (index > 3) {
      cell.style = {
        font: { bold: "true", size: fontSize },
        alignment: {
          horizontal: "center",
          vertical: "top",
          wrapText: "true",
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "bebebe" },
        },
      };
    }
  });
  rowIndex++;

  worksheet.addRows([["", "", "", "", "", "DZ", "SCREEN"]]);

  worksheet.getRow(rowIndex)._cells.forEach((cell, index) => {
    if (index > 3) {
      cell.style = {
        font: { bold: "true", size: fontSize },
        alignment: {
          horizontal: "center",
          vertical: "top",
          wrapText: "true",
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "f1f1f1" },
        },
      };
    }
  });
  rowIndex++;

  const productFlavoredCake = products.filter(
    (product) => product.name.toLowerCase() === "flavored cake"
  );
  if (productFlavoredCake && productFlavoredCake.length) {
    const chocolate = productFlavoredCake[0].items.filter((item) =>
      ["chocolate glazed", "double chocolate"].includes(item.name.toLowerCase())
    );
    const chocolateDZ = chocolate.reduce(
      (acc, item) => {
        acc.dz = acc.dz + item.total / 12;
        return acc;
      },
      { name: "Chocolate Glazed", dz: 0 }
    );

    productFlavoredCake[0].items.forEach((item, index) => {
      if (
        !["chocolate glazed", "double chocolate"].includes(
          item.name.toLowerCase()
        )
      ) {
        const itemDZ = (item.total / 12).toFixed(3);
        const itemScreen = (itemDZ / 3).toFixed(3);
        worksheet.addRows([["", "", "", "", item.name, itemDZ, itemScreen]]);
        worksheet.getRow(rowIndex)._cells.forEach((cell, index) => {
          if (index > 3) {
            cell.style = {
              font: { bold: index === 0 ? "true" : "false", size: fontSize },
              alignment: {
                horizontal: "center",
                vertical: "top",
                wrapText: "true",
              },
              fill: {
                type: "pattern",
                pattern: "none",
              },
            };
          }
        });
        rowIndex++;
      }
    });

    worksheet.addRows([
      [
        "",
        "",
        "",
        "",
        chocolateDZ.name,
        chocolateDZ.dz.toFixed(3),
        (chocolateDZ.dz / 3).toFixed(3),
      ],
    ]);
    worksheet.getRow(rowIndex)._cells.forEach((cell, index) => {
      if (index > 3) {
        cell.style = {
          font: { bold: index === 0 ? "true" : "false", size: fontSize },
          alignment: {
            horizontal: "center",
            vertical: "top",
            wrapText: "true",
          },
          fill: {
            type: "pattern",
            pattern: "none",
          },
        };
      }
    });
    rowIndex++;
  }

  const productPlainCake = products.filter(
    (product) => product.name.toLowerCase() === "cake"
  );
  if (productPlainCake && productPlainCake.length) {
    const productCakeDZ = productPlainCake[0].total;
    const productCakeScreen = productCakeDZ / 3;
    worksheet.addRows([
      [
        "",
        "",
        "",
        "",
        productPlainCake[0].name,
        productCakeDZ.toFixed(3),
        productCakeScreen.toFixed(3),
      ],
    ]);
    worksheet.getRow(rowIndex)._cells.forEach((cell, index) => {
      if (index > 3) {
        cell.style = {
          font: { bold: index === 0 ? "true" : "false", size: fontSize },
          alignment: {
            horizontal: "center",
            vertical: "top",
            wrapText: "true",
          },
          fill: {
            type: "pattern",
            pattern: "none",
          },
        };
      }
    });
    rowIndex++;
  }

  const productRings = products.filter(
    (product) => product.name.toLowerCase() === "rings"
  );
  if (productRings && productRings.length) {
    const itemGlazed = productRings[0].items.filter(
      (item) => item.name.toLowerCase() === "glazed"
    );
    if (itemGlazed && itemGlazed.length) {
      const itemGlazedDZ = itemGlazed[0].total * 3;
      const itemGlazedScreen = itemGlazedDZ / 3;
      worksheet.addRows([
        [
          "",
          "",
          "",
          "",
          `${itemGlazed[0].name} Rings`,
          itemGlazedDZ.toFixed(3),
          itemGlazedScreen.toFixed(3),
        ],
      ]);
      worksheet.getRow(rowIndex)._cells.forEach((cell, index) => {
        if (index > 3) {
          cell.style = {
            font: { bold: index === 0 ? "true" : "false", size: fontSize },
            alignment: {
              horizontal: "center",
              vertical: "top",
              wrapText: "true",
            },
            fill: {
              type: "pattern",
              pattern: "none",
            },
          };
        }
      });
      rowIndex++;
    }
  }

  worksheet.insertRow(1, ["", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  worksheet.getRow(1)._cells.forEach(
    (cell, index) =>
      (cell.style = {
        font: { bold: "false", size: fontSize },
        alignment: {
          horizontal: "center",
          vertical: "top",
          wrapText: "true",
        },
      })
  );

  try {
    workbook.xlsx.writeBuffer("test.xlsx").then((buffer) => {
      const blob = new Blob([buffer], { type: "applicationi/xlsx" });
      saveAs(blob, `${printOrderDate}.xlsx`);
    });
  } catch (error) {
    console.log(error);
  }
}

function getStores(orders) {
  return orders
    .map((order) => ({
      id: order.store.id,
      name: order.store.code,
      priority: order.store.priority,
    }))
    .sort((a, b) => +a.priority - +b.priority);
}

function getProducts(orders) {
  var items = orders.reduce((acc, order) => {
    const details = order.orderDetails;
    for (const item of details) {
      const key = `product_${item.productItem.product.id}`;
      if (!acc[key]) {
        acc[key] = {
          id: item.productItem.product.id,
          name: item.productItem.product.name,
          items: [],
          total: 0,
        };
      }
      const itemIndex = acc[key]["items"].findIndex(
        (i) => i.id === item.productItem.id
      );
      if (itemIndex === -1) {
        acc[key]["items"].push({
          id: item.productItem.id,
          name: item.productItem.name,
          total: 0,
        });
        acc[key]["items"].sort((a, b) => a.name.localeCompare(b.name));
      }
    }
    return acc;
  }, {});

  let products = Object.values(items)
    .map((item) => {
      item.priority = getProductPriority(item.id);
      item.items.sort((a, b) => a.name.localeCompare(b.name));
      return item;
    })
    .sort((a, b) => +a.priority - +b.priority);

  return products;
}

function getProductPriority(productId) {
  if (productId === 10) return 1;
  else if (productId === 9) return 2;
  else if (productId === 6) return 3;
  else if (productId === 5) return 4;
  else if (productId === 4) return 5;
  else if (productId === 6) return 6;
  return 7;
}

function getItems(orders) {
  const items = orders.reduce((acc, order) => {
    const details = order.orderDetails;
    for (const item of details) {
      const key = `item_${order.store.id}_${item.productItem.product.id}_${item.productItem.id}`;
      acc[key] = {
        qty: item.quantity,
        tQty: calculateQtyTotal(
          item.quantity,
          item.productItem.unit.operator,
          item.productItem.unit.operatorValue
        ),
      };
    }
    return acc;
  }, {});
  return items;
}

function calculateQtyTotal(quantity, operator, operatorValue) {
  switch (operator) {
    case "/":
      return quantity / operatorValue;
    case "*":
      return quantity * operatorValue;
    default:
      return quantity;
  }
}

function getSummary(products) {
  // get total rings
  const totalRings = getTotalRings(products);
  const totalShells = getTotalShells(products);
  const glazed = getGlazedItems(products);
  const munchkins = otherMunkinItems(products);
  const fancyItems = getFancyItems(products);
  const summary = [];

  summary.push(["For Baker", "", "", "", "Basket", ""]);
  summary.push(["", "DZ", "Screen", "", "", ""]);
  summary.push([...totalRings]);
  summary.push([...totalShells]);
  summary.push([]);
  summary.push([...glazed]);
  summary.push([]);
  summary.push(...fancyItems);

  let summaryIndex = 2;
  munchkins.forEach((item) => {
    summary[summaryIndex][4] = item[0];
    summary[summaryIndex][5] = item[1];
    summaryIndex++;
  });

  return summary;
}

function getTotalRings(products) {
  const totalRings = [];
  const productRings = products.filter(
    (product) => product.name.toLowerCase() === "rings"
  );

  if (productRings && productRings.length > 0) {
    const ringScreen = productRings[0].total / 3;
    totalRings.push("Total Rings");
    totalRings.push(productRings[0].total.toFixed(3));
    totalRings.push(ringScreen.toFixed(3));
  }

  return totalRings;
}

function getTotalShells(products) {
  const totalShells = [];
  const productShells = products.filter(
    (product) => product.name.toLowerCase() === "shell"
  );
  if (productShells && productShells.length) {
    const shellScreen = productShells[0].total / 3;
    totalShells.push("Total Shells");
    totalShells.push(productShells[0].total.toFixed(3));
    totalShells.push(shellScreen.toFixed(3));
  }

  return totalShells;
}

function getGlazedItems(products) {
  const productMunchkins = products.filter(
    (product) => product.name.toLowerCase() === "munchkins"
  );

  const munchkinsGlazed = productMunchkins[0].items.filter(
    (item) => item.name.toLowerCase() === "glazed"
  );

  return munchkinsGlazed.length
    ? [munchkinsGlazed[0].name, munchkinsGlazed[0].total.toFixed(3)]
    : [];
}

function otherMunkinItems(products) {
  const munkinItems = [];
  const productMunchkins = products.filter(
    (product) => product.name.toLowerCase() === "munchkins"
  );
  if (productMunchkins && productMunchkins.length) {
    const itemMunchkins = productMunchkins[0].items.filter((item) =>
      ["powdered", "blueberry", "chocolate", "pumpkin"].includes(item.name.toLowerCase())
    );
    itemMunchkins.forEach((item, index) => {
      munkinItems.push([item.name, item.total.toFixed(3), ""]);
    });
  }
  return munkinItems;
}

function getFancyItems(products) {
  const fancyItems = [];
  const productFancy = products.filter(
    (product) => product.name.toLowerCase() === "fancy"
  );
  if (productFancy && productFancy.length) {
    productFancy[0].items.forEach((item, index) => {
      fancyItems.push([item.name, item.total, ""]);
    });
  }
  return fancyItems;
}
