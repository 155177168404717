import React from "react";
import AppNav from "./Navbar";

const Header = () => {
	return (
		<>
			<AppNav />
		</>
	);
};

export default Header;
