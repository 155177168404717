import React from "react";
import { Spinner } from "react-bootstrap";

const Loading = () => {
	return (
		<>
			<Spinner animation="grow" variant="dark" />
		</>
	);
};

export default Loading;
