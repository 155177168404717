import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import FormikControl from "../../../../components/formik/FormikControl";
import {
  addProductItem,
  editProductItem,
  getProductItemById,
} from "../../../../services/product-item";
import { getUnits } from "../../../../services/product-unit";
import { getProducts } from "../../../../services/product";

const initialValues = {
  id: 0,
  name: "",
  active: true,
  unit: {id: ''},
  product: {id: ''},
  price: 0
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name Required!"),
  unit: Yup.object({
    id: Yup.number().required("Unit Selection Required!"),
  }),
  product: Yup.object({
    id: Yup.number().required("Product Selection Required!"),
  }),
  active: Yup.boolean(),
  price: Yup.number('Price must be number!').required('Price Required!')
});

const AddEditProductItem = ({
  productItemId,
  onProductItemUpdate,
  onError,
}) => {
  const [productItem, setProductItem] = useState(initialValues);
  const [units, setUnits] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUnits = async () => {
      const { data, error } = await getUnits();
      if (error) {
        toast.error("Error, while loading Units! Please contact Web Admin!");
      } else {
        const itemUnits = data.map((item) => ({
          value: item.id,
          key: item.name,
        }));
        setUnits(itemUnits);
      }
    };
    fetchUnits();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      const { data, error } = await getProducts();
      if (error) {
        toast.error("Error, while loading Products! Please contact Web Admin!");
      } else {
        const itemProducts = data.map((item) => ({
          value: item.id,
          key: item.name,
        }));
        setProducts(itemProducts);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchProductItem = async () => {
      const { data, error } = await getProductItemById(productItemId);
      if (error) {
        toast.error(
          "Error, while loading Product Item! Please contact Web Admin!"
        );
      } else {
        const item = {
          name: data.name,
          active: data["active"] === 1 ? true : false,
          unit: data.unit,
          product: data.product,
          price: data.price,
        };
        setProductItem(item);
      }
    };
    if (productItemId > 0) fetchProductItem();
  }, [productItemId]);

  const onSubmit = (values) => {
    setLoading(true);

    if (productItemId === 0) {
      handleAddProductItem(values);
      delete values.id;
    } else {
      handleEditProductItem(values);
    }
  };

  const handleAddProductItem = async (values) => {
    delete values.id;
    values.active = values.active ? 1 : 0;
    values.unit.id = +values.unit.id;
    values.product.id = +values.product.id;
    try {
      const { data, error, messages } = await addProductItem(values);
      if (!error) {
        // success
        toast.success(`Product created successfully!`);
        onProductItemUpdate(data);
      } else {
        toast.error(messages.join(","));
        onError();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleEditProductItem = async (values) => {
    delete values.id;
    values.active = values.active ? 1 : 0;
    values.unit.id = +values.unit.id;
    values.product.id = +values.product.id;
    try {
      const { data, error, messages } = await editProductItem(
        productItemId,
        values
      );
      if (!error) {
        // success
        toast.success(`Product updated successfully!`);
        onProductItemUpdate(data);
      } else {
        toast.error(messages.join(","));
        onError();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={productItem}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <>
            <Form autoComplete="off">
              <FormikControl
                control="input"
                type="text"
                name="name"
                label="Name"
                placeholder="Enter Name"
              />
              <FormikControl
                control="select"
                name="unit.id"
                label="Unit"
                options={[{ value: "", key: "Select Unit" }, ...units]}
              />
              <FormikControl
                control="select"
                name="product.id"
                label="Product"
                options={[{ value: "", key: "Select Product" }, ...products]}
              />
              <FormikControl 
                control="input"
                name="price"
                label="Amount"
                type="number"
              />
              <FormikControl
                control="singleCheckBox"
                name="active"
                label="Active?"
              />
              <div className="d-flex justify-content-between">
                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? "Please wait..." : "Submit"}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default AddEditProductItem;
