import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import AppLayout from '../layouts/App'
import AuthLayout from '../layouts/Auth'

const Pages = () => {
  return (
	  <Routes>
      <Route path="" element={<Navigate replace to="auth" />} />
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="app/*" element={<AppLayout />} />
    </Routes>
  )
}

export default Pages