import { useSelector } from "react-redux";

export const usePermissions = (moduleName, moduleAccessOf = false) => {
  const {
    permissions: { permissions },
  } = useSelector((state) => state.auth);

  const getModulePermissions = permissions
      .filter((permission) => permission.module.name === moduleName)
      .map((permission) => {
        let moduleAccess = permission.access;
        if(moduleAccessOf) {
          moduleAccess += `_${permission.accessOf}`;
        }
        return moduleAccess;
      });


  return getModulePermissions;
};
