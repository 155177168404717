import axios from "axios";
import history from "../history";
import { toast } from "react-toastify";

const service = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	timeout: 60000,
});

// Config
const ENTRY_ROUTE = "/auth/login";
const TOKEN_PAYLOAD_KEY = "Authorization";
const PUBLIC_REQUEST_KEY = "public-request";
const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;

// API Request interceptor
service.interceptors.request.use(
	(config) => {
		const jwtToken = localStorage.getItem(AUTH_TOKEN);

		if (jwtToken) {
			config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
		}
		config.headers['Access-Control-Allow-Origin'] = '*';
		

		if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
			history.push(ENTRY_ROUTE);
			window.location.reload();
		}

		return config;
	},
	(error) => {
		// Do something with request error here
		toast.error("Error");
		Promise.reject(error);
	}
);

// API respone interceptor
service.interceptors.response.use(
	(response) => {
		return response.data;
	},
	(error) => {
		let data = error.response?.data;
		let message = data?.messages || [];
		let errorMeg = message.length
			? message.reduce(
					(accumulator, message) => accumulator + message + "\n"
			  )
			: "";
		let notificationParam = {
			message: "Following Error occured",
			description: errorMeg,
		};

		if (error.response?.status) {
			// Remove token and redirect
			if (error.response.status >= 400 && error.response.status <= 403) {
				notificationParam.message = "Authentication Fail";
				notificationParam.description = "Please login again";
				localStorage.removeItem(AUTH_TOKEN);
				history.push(ENTRY_ROUTE);
				//window.location.reload();
			}

			if (error.response.status === 404) {
				notificationParam.message = "Not Found";
			}

			if (error.response.status === 406) {
				notificationParam.message = errorMeg;
			}

			if (error.response.status === 500) {
				notificationParam.message = "Internal Server Error";
			}

			if (error.response.status === 508) {
				notificationParam.message = "Time Out";
			}
		} else {
			notificationParam.message = "Internal Server Error";
			notificationParam.description = "Something went wrong";
		}
		toast.error(notificationParam.message);
		return Promise.reject(error);
	}
);

export default service;
