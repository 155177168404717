import http from './http'

const getProductItems = () => {
    return http({
        method: 'get',
        url: '/v1/productitems'
    })
}

const getProductItemById = (id) => {
    return http({
        method: 'get',
        url: `/v1/productitems/${id}`
    })
}

const addProductItem = (values) => {
    return http({
        method: 'post',
        url: '/v1/productitems',
        data: values
    })
}

const editProductItem = (id, values) => {
    return http({
        method: 'put',
        url: `/v1/productitems/${id}`,
        data: values
    })
}

export {
    getProductItems,
    getProductItemById,
    addProductItem,
    editProductItem
}