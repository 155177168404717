import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { BsFillPencilFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import Loading from "../../../components/ui/Loading";
import Drawer from "../../../components/ui/Drawer";
import { getProducts } from "../../../services/product";
import AddEditProduct from "./AddEditProduct";
import { usePermissions } from "../../../hooks/usePermissions";

import AppTable from "../../../components/ui/Table";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [productId, setProductId] = useState("");
  const navigate = useNavigate();

  const productPermission = usePermissions("MANAGE_PRODUCT");

  const columns = [
    { path: "name", label: "Name" },
    {
      label: "Status",
      key: "active",
      content: (product) => <>{product.active === 1 ? "Active" : "Inactive"}</>,
    },
    {
      label: "Actions",
      key: "action",
      content: (product) => (
        <>
          {productPermission.includes("MODIFY") && (
            <Button
              type="button"
              variant="default"
              onClick={() => handleAddEditProduct(product.id)}
              title="Edit"
            >
              <BsFillPencilFill />
            </Button>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data, error } = await getProducts();
        if (!error) {
          setProducts(data);
          setPageLoading(false);
        } else {
          alert("show errors");
        }
      } catch (err) {
        console.error("Getting error while fetching products");
      }
    };
    fetchProducts();
  }, []);

  const handleAddEditProduct = (id) => {
    setProductId(id);
    setShow(true);
  };

  const handleHide = () => {
    setShow(false);
  };

  const handleProductUpdate = (data) => {
    setShow(false);
    let availableProducts = [...products];
    const product = availableProducts.filter(
      (product) => product.id === data.id
    );
    if (product.length) {
      availableProducts = availableProducts.filter(
        (product) => product.id !== data.id
      );
      setProducts((prev) => [data, ...availableProducts]);
    } else {
      setProducts((prev) => [data, ...prev]);
    }
  };

  const handleManageItem = () => {
    navigate(`/app/products/items`);
  };

  if (!productPermission.includes("VIEW")) {
    navigate('/app/dashboard');
  }

  if (pageLoading) {
    return <Loading />;
  }

  return (
    <div className="app-layout">
      <div className="d-flex justify-content-between">
        <div className="ms-2 mb-2">
          <h3>Products</h3>
        </div>
        {productPermission.includes("CREATE") && (
          <div className="me-2 mb-2">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleAddEditProduct(0)}
            >
              Add Product
            </button>
            <button
              type="button"
              className="btn btn-primary ms-3"
              onClick={handleManageItem}
            >
              Manage Items
            </button>
          </div>
        )}
      </div>
      <AppTable dataSource={products} columns={columns} />
      <Drawer
        show={show}
        onHide={handleHide}
        title={productId > 0 ? "Edit Product" : "Add Product"}
      >
        <AddEditProduct
          productId={productId}
          onProductUpdate={(data) => handleProductUpdate(data)}
          onError={handleHide}
        />
      </Drawer>
    </div>
  );
};

export default Products;
