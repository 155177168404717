import {Table} from 'react-bootstrap'
import AppTableBody from './AppTableBody'
import AppTableHeader from './AppTableHeader'

const AppTable = ({dataSource, columns}) => {
	return (
		<Table responsive hover bordered striped>
			<AppTableHeader columns={columns} />
			<AppTableBody columns={columns} dataSource={dataSource} />
		</Table>
	)
}

export default AppTable