import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Table } from "react-bootstrap";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import * as dayjs from "dayjs";
import FormikControl from "../../../components/formik/FormikControl";
import { getProductItems } from "../../../services/product-item";
import TextError from "../../../components/formik/TextError";
import {
  addOrder,
  getOrderById,
  editOrder,
  getAverageOrder,
} from "../../../services/order";
import { useNavigate, useParams } from "react-router-dom";
import { useUserContext } from "../../../store/context/User";

const initialValues = {
  id: 0,
  orderDate: null,
  status: "Pending",
  store: "",
  orderDetails: [],
};

const validationSchema = Yup.object({
  orderDate: Yup.date().required("Order date required!").nullable(),
  store: Yup.string().required("Store selection required!"),
});

const AddEditOrder = () => {
  const [order, setOrder] = useState(initialValues);
  const { userStores } = useSelector((state) => state.store);
  const [loading, setLoading] = useState(false);
  const [orderDate, setOrderDate] = useState(null);
  const [storeId, setStoreId] = useState("");
  const [averageOrders, setAverageOrders] = useState({});
  const [canPlaceOrder, setCanPlaceOrder] = useState(true);

  const { type: userRole } = useUserContext();
  const navigate = useNavigate();
  let { orderId, waste, safetyOrder } = useParams();
  orderId = +orderId;
  waste = waste === "true";

  const canPlace = () => {
    if (userRole.toLowerCase() === "admin") return true;
    if (waste) return true;
    if (safetyOrder) return true;

    const today = dayjs();
    const curr = dayjs(orderDate);
    if (!curr.isAfter(today)) return false;

    const next = dayjs().set("hour", 14).set("minute", 0).set("second", 0);

    return next.isAfter(today);
  };

  const getStoreOptions = (userStores) => {
    const stores = userStores.map((item) => ({
      value: item.id,
      key: item.name,
    }));
    return stores.length === 1
      ? stores
      : [{ key: "Pick a store", value: "" }, ...stores];
  };

  const storeOptions = getStoreOptions(userStores);

  const onSubmit = (values) => {
    setLoading(true);
    delete values.id;
    values.store = { id: +values.store };
    values.orderDate = dayjs(values.orderDate).format("YYYY-MM-DD");
    if (orderId === 0) {
      handleAddOrder(values);
    } else {
      handleEditOrder(values);
    }
  };

  const isEmpty = (value) => {
    return (
      // null or undefined
      value == null ||
      // has length and it's zero
      (value.hasOwnProperty("length") && value.length === 0) ||
      // is an Object and has no keys
      (value.constructor === Object && Object.keys(value).length === 0)
    );
  };

  const validateQuantity = (value) => {
    let error;
    if (isEmpty(value)) {
      error = "Quantity must be a number!";
    } else if (value < 0) {
      error = "Quantity must be 0 or Grater!";
    }
    return error;
  };

  const handleAddOrder = async (values) => {
    const { data, error, messages } = await addOrder(values);
    if (!error) {
      // success
      toast.success(`Order created successfully!`);
      navigate("/app/orders");
    } else {
      toast.error(messages.join(","));
      setLoading(false);
    }
  };

  const handleEditOrder = async (values) => {
    try {
      const { data, error, messages } = await editOrder(orderId, values);

      if (!error) {
        // success
        toast.success(`Order updated successfully!`);
        navigate("/app/orders");
      } else {
        toast.error(messages.join(","));
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchProductItems = async () => {
      try {
        const { data, error } = await getProductItems();
        if (!error) {
          data.sort((a, b) => {
            const nameA = a.product.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.product.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
          const items = data
            .filter((item) => item.active === 1)
            .map((item) => ({
              productItem: {
                id: item.id,
                name: item.name,
                product: item.product,
              },
              quantity: 0,
              wasteQuantity: 0,
            }));

          const orderInitials = { ...order };
          if (storeOptions.length === 1) {
            orderInitials.store = storeOptions[0].value;
          }

          const orderItem = { ...orderInitials, orderDetails: [...items] };

          setOrderDate(dayjs().format("YYYY-MM-DD"));

          setOrder(orderItem);
        } else {
          alert("show errors");
        }
      } catch (err) {
        console.error("Getting error while fetching products");
      }
    };

    const fetchOrderById = async () => {
      try {
        const { data, error } = await getOrderById(orderId);
        if (!error) {
          if (safetyOrder) {
            const { data: productItems } = await getProductItems();
            const plainItems = productItems
              .filter((item) => item.active === 1)
              .reduce((acc, item) => {
                const key = item.id.toString();
                if (!acc[key]) {
                  acc[key] = {
                    productItem: {
                      id: item.id,
                      name: item.name,
                      product: item.product,
                    },
                    quantity: 0,
                    wasteQuantity: 0,                    
                  };
                }
                return acc;
              }, {});
              const orderItems = data.orderDetails.map((item) => item.productItem.id.toString());
              Object.keys(plainItems).forEach((key) => {
                if (!orderItems.includes(key)) {
                  data.orderDetails.push(plainItems[key]);
                }
              });
          }

          data.orderDetails.sort((a, b) => {
            const nameA = a.productItem.product.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.productItem.product.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          });

          const item = {
            id: data.id,
            orderDate: new Date(
              dayjs(data.orderDate)
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
            ),
            store: data.store.id,
            orderDetails: data.orderDetails.map((orderItem) => ({
              productItem: {
                id: orderItem.productItem.id,
                name: orderItem.productItem.name,
                product: orderItem.productItem.product,
              },
              quantity: orderItem.quantity || 0,
              wasteQuantity: orderItem.wasteQuantity || 0,
            })),
          };

          setOrderDate(
            dayjs(item.orderDate)
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .format("YYYY-MM-DD")
          );
          setStoreId(item.store);
          setOrder(item);
        } else {
          alert("show errors");
        }
      } catch (err) {
        console.error("Getting error while fetching products");
      }
    };

    if (orderId === 0) {
      fetchProductItems();
    } else {
      fetchOrderById();
    }
    setCanPlaceOrder(canPlace());
    setLoading(false);
  }, [orderId, safetyOrder]);

  useEffect(() => {
    const getAverageOrders = async () => {
      const averageOrders = await getAverageOrder(orderDate, storeId);
      if (!averageOrders.error && averageOrders.data.length) {
        const averageQuantity = averageOrders.data.reduce((acc, item) => {
          const key = item.id.toString();
          if (!acc[key]) {
            acc[key] = {
              qty: 0,
              waste: 0,
            };
          }
          acc[key] = {
            qty: item.avgQuantity,
            waste: item.wasteQuantity,
          };
          return acc;
        }, {});
        setAverageOrders(averageQuantity);
      }
    };
    if (orderDate && orderDate !== "" && storeId !== "") {
      getAverageOrders();
    }
    if (orderDate !== "") {
      setCanPlaceOrder(canPlace());
    }
  }, [orderDate, storeId]);

  const handleStoreChange = (e) => {
    setOrder((prev) => ({ ...prev, store: e.target.value }));
    setStoreId(e.target.value);
  };

  const handleOrderDateChange = (date) => {
    const orderDate = dayjs(date).format("YYYY-MM-DD");
    setOrderDate(orderDate);
    //setCanPlaceOrder(canPlace());
  };

  const getAverageQty = (productItemId) => {
    if (
      averageOrders &&
      averageOrders[productItemId] &&
      averageOrders[productItemId].qty
    ) {
      return averageOrders[productItemId].qty.toFixed(2);
    } else {
      return 0;
    }
  };

  const getLastWeekWaste = (productItemId) => {
    if (
      averageOrders &&
      averageOrders[productItemId] &&
      averageOrders[productItemId].waste
    ) {
      return averageOrders[productItemId].waste.toFixed(2);
    } else {
      return 0;
    }
  };

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  return (
    <>
      <div className="app-layout">
        <Formik
          enableReinitialize
          initialValues={order}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <>
              <Form onKeyDown={onKeyDown}>
                <FormikControl
                  control="select"
                  name="store"
                  label="Pick a store"
                  options={storeOptions}
                  disabled={waste ? "disabled" : ""}
                  onChange={handleStoreChange}
                />
                <FormikControl
                  control="date"
                  name="orderDate"
                  label="Order Date"
                  placeholder="Enter Order Date"
                  className="col-md-3"
                  disabled={waste ? "disabled" : ""}
                  onDateChange={handleOrderDateChange}
                />
                <div className="mb-3">
                  <FieldArray name="orderDetails">
                    {(fieldArrayProps) => {
                      const { form } = fieldArrayProps;
                      const { values } = form;
                      const { orderDetails } = values;
                      let prvProduct = "";

                      return (
                        <>
                          <Table bordered hover>
                            <thead>
                              <tr>
                                <th width="30%">Item Name</th>
                                <th width={waste ? "80%" : "20%"}>
                                  {waste ? "Waste Quantity" : "Quantity"}
                                </th>
                                <th width="20%">
                                  {!waste ? "Waste Quantity" : "Quantity"}
                                </th>
                                {!waste ? (
                                  <>
                                    <th
                                      width="20%"
                                      title="Waste Quantity of last week same day"
                                    >
                                      Last week Waste
                                    </th>
                                    <th title="Last 4 week average of same day">
                                      Average Quantity
                                    </th>
                                  </>
                                ) : null}
                              </tr>
                            </thead>
                            <tbody>
                              {orderDetails.map((order, index) => {
                                const qunatityString = waste
                                  ? "wasteQuantity"
                                  : "quantity";
                                const productName =
                                  order.productItem.product.name;
                                let showProduct = false;
                                if (prvProduct !== productName) {
                                  showProduct = true;
                                  prvProduct = productName;
                                }
                                return (
                                  <React.Fragment key={index}>
                                    {showProduct ? (
                                      <tr>
                                        <td
                                          style={{ backgroundColor: "#E9ECEF" }}
                                          colSpan={5}
                                        >
                                          {productName}
                                        </td>
                                      </tr>
                                    ) : null}
                                    <tr>
                                      <td>{order.productItem.name}</td>
                                      <td className="text-center">
                                        <Field
                                          type="number"
                                          name={`orderDetails[${index}].${qunatityString}`}
                                          className="form-control form-control-md w-50"
                                          validate={validateQuantity}
                                          size={10}
                                          step={0.1}
                                        />
                                        <ErrorMessage
                                          name={`orderDetails[${index}].${qunatityString}`}
                                          component={TextError}
                                        />
                                      </td>
                                      <td>
                                        {waste
                                          ? order.quantity
                                          : order.wasteQuantity}
                                      </td>
                                      {!waste ? (
                                        <>
                                          <td align="center">
                                            {getLastWeekWaste(
                                              order.productItem.id.toString()
                                            )}
                                          </td>
                                          <td align="center">
                                            {getAverageQty(
                                              order.productItem.id.toString()
                                            )}
                                          </td>
                                        </>
                                      ) : null}
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                          </Table>
                        </>
                      );
                    }}
                  </FieldArray>
                  <div className="d-flex justify-content-between">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={loading || !canPlaceOrder ? "disabled" : ""}
                    >
                      {loading ? "Please wait..." : "Submit"}
                    </Button>
                    {!canPlaceOrder && (
                      <div style={{ color: "red" }}>
                        You can not edit previous order! Please ask Admin.
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddEditOrder;
