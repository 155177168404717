import React, { useEffect, useState } from "react";
import { useUserContext } from "../../../store/context/User";
import { getOrders } from "../../../services/order";
import * as dayjs from "dayjs";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Badge, Card, Table } from "react-bootstrap";
import { BsFillCheckCircleFill } from "react-icons/bs";

const Dashboard = () => {
  const [orderDate] = useState(() =>
    dayjs().add(1, "day").format("MM-DD-YYYY")
  );
  const [orders, setOrders] = useState([]);
  const { fullName } = useUserContext();
  const { userStores } = useSelector((state) => state.store);

  useEffect(() => {
    const fetchOrders = async () => {
      const { data, error } = await getOrders("orderDate=" + orderDate);

      if (!error) {
        const stores = data.map((order) => order.store.id);
        const uniqueStores = [...new Set(stores)];
        setOrders(uniqueStores);
      } else {
        toast.error("Error fetching orders");
      }
    };

    fetchOrders();
  }, []);

  return (
    <>
      <div>Welcome back, {fullName}!</div>
      <br />
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between">
            <div>Orders</div>
            <div>
              <b>{orderDate}</b> <Badge>{dayjs(orderDate).format('ddd')}</Badge>
            </div>
          </div> 
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Code</th>
                <th>Store</th>
                <th>Order Placed?</th>
              </tr>
            </thead>
            <tbody>
              {userStores.map((store, index) => {
                const doesOrderExist = orders.includes(store.id);
                console.log(doesOrderExist, store.id)
                return (
                  <tr key={`store${store.id}`}>
                    <td>{index + 1}</td>
                    <td>{store.code}</td>
                    <td>{store.name}</td>
                    <td>{doesOrderExist ? (<BsFillCheckCircleFill style={{color: 'green'}} />) : <></>}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default Dashboard;
