import http from './http'

const getStores = () => {
    return http({
        method: 'get',
        url: '/v1/stores?sortby=priority:asc'
    })
}

const getStoreById = (id) => {
    return http({
        method: 'get',
        url: `/v1/stores/${id}`
    })
}

const addStore = (values) => {
    return http({
        method: 'post',
        url: '/v1/stores',
        data: values
    })
}

const editStore = (id, values) => {
    return http({
        method: 'put',
        url: `/v1/stores/${id}`,
        data: values
    })
}

export {
    getStores,
    getStoreById,
    addStore,
    editStore
}