import http from './http'

const getProducts = () => {
    return http({
        method: 'get',
        url: '/v1/products'
    })
}

const getProductById = (id) => {
    return http({
        method: 'get',
        url: `/v1/products/${id}`
    })
}

const addProduct = (values) => {
    return http({
        method: 'post',
        url: '/v1/products',
        data: values
    })
}

const editProduct = (id, values) => {
    return http({
        method: 'put',
        url: `/v1/products/${id}`,
        data: values
    })
}

export {
    getProducts,
    getProductById,
    addProduct,
    editProduct
}