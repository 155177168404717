import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import FormikControl from "../../../components/formik/FormikControl";
import {
  addProduct,
  editProduct,
  getProductById,
} from "../../../services/product";

const initialValues = {
  id: 0,
  name: "",
  active: true,
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name Required!"),
  active: Yup.boolean(),
});

const AddEditProduct = ({ productId, onProductUpdate, onError }) => {
  const [product, setProduct] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      const { data, error } = await getProductById(productId);
      if (error) {
        toast.error("Error, while loading Product! Please contact Web Admin!");
      } else {
        const product = {
            name: data.name,
            active: data["active"] === 1 ? true : false
        }
        setProduct(product);
      }
    };
    if (productId > 0) fetchProduct();
  }, [productId]);

  const onSubmit = (values) => {
    setLoading(true);

    if (productId === 0) {
      handleAddProduct(values);
      delete values.id;
    } else {
      handleEditProduct(values);
    }
  };

  const handleAddProduct = async (values) => {
    delete values.id;
    values.active = values.active ? 1 : 0;
    try {
      const { data, error, messages } = await addProduct(values);
      if (!error) {
        // success
        toast.success(`Product created successfully!`);
        onProductUpdate(data);
      } else {
        toast.error(messages.join(","));
        onError();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleEditProduct = async (values) => {
    delete values.id;
    values.active = values.active ? 1 : 0;
    try {
      const { data, error, messages } = await editProduct(productId, values);
      if (!error) {
        // success
        toast.success(`Product updated successfully!`);
        onProductUpdate(data);
      } else {
        toast.error(messages.join(","));
        onError();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={product}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <>
            <Form autoComplete="off">
              <FormikControl
                control="input"
                type="text"
                name="name"
                label="Name"
                placeholder="Enter Name"
              />
              <FormikControl
                control="singleCheckBox"
                name="active"
                label="Active?"
              />
              <div className="d-flex justify-content-between">
                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? "Please wait..." : "Submit"}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default AddEditProduct;
