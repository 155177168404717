import React from "react";
import _ from "lodash";

const AppTableBody = ({ dataSource, columns }) => {
	const renderCell = (item, column) => {
		if (column.content) return column.content(item);
		return _.get(item, column.path);
	};

	const createKey = (item, column) => {
		return item.id + (column.path || column.key); 
	}

	return (
		<tbody>
			{
				dataSource.length > 0 ? 
					dataSource.map((item) => (
					<tr key={item.id}>
						{columns.map((column) => (
							<td key={createKey(item, column)}>
								{renderCell(item, column)}
							</td>
						))}
					</tr>
				)) : (
					<tr>
						<td colspan={columns.length}>No data</td>
					</tr>
				)
			}
		</tbody>
	);
};

export default AppTableBody;
