import http from './http'

const getOrders = (queryParams='') => {
    return http({
        method: 'get',
        url: `/v1/orders?${queryParams}`
    })
}

const getOrderById = (id) => {
    return http({
        method: 'get',
        url: `/v1/orders/${id}`
    })
}

const addOrder = (values) => {
    return http({
        method: 'post',
        url: '/v1/orders',
        data: values
    })
}

const addPreOrder = (values) => {
    return http({
        method: 'post',
        url: '/v1/orders/preorder',
        data: values
    })
}

const editOrder = (id, values) => {
    return http({
        method: 'put',
        url: `/v1/orders/${id}`,
        data: values
    })
}

const getAverageOrder = (orderDate, storeId) => {
    const url = `/v1/orders/avgproductitem?orderDate=${orderDate}&store.id=${storeId}`;
    return http({
        method: 'get',
        url
    })
}

const getAccountReport = (startDate, endDate) => {
    const url = `/v1/reports/account?orderStartDate=${startDate}&orderEndDate=${endDate}`;
    return http({
        method: 'get',
        url
    })
}

const getWasteReport = (payload) => {
    const { orderStartDate, orderEndDate, store, avg } = payload;
    const url = `/v1/orders/avgreport?orderStartDate=${orderStartDate}&orderEndDate=${orderEndDate}&store.id=${store}&avg=${avg}`;
    return http({
        method: 'get',
        url
    })
}

export {
    getOrders,
    getOrderById,
    addOrder,
    editOrder,
    getAverageOrder,
    getAccountReport,
    addPreOrder,
    getWasteReport
}