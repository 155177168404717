import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import Stores from "./Stores";
import Products from "./Products";
import Users from "./Users";
import Orders from "./Orders";
import AddEditStore from "./Stores/AddEditStore";
import ProductItems from "./Products/items";
import PrintOrder from "./Orders/PrintOrder";
import AddEditOrder from "./Orders/AddEditOrder";
import AccountReport from "./Orders/AccountReport";
import WasteItem from "./Orders/WasteItem";

const AppPages = () => {
  return (
    <Routes>
      <Route path="" element={<Navigate replace to="dashboard" />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="stores/*">
        <Route path="" element={<Stores />} />
        <Route path=":id" element={<AddEditStore />} />
      </Route>
      <Route path="products/*">
        <Route path="" element={<Products />} />
        <Route path="items" element={<ProductItems />} />
      </Route>
      <Route path="users/*">
        <Route path="" element={<Users />} />
      </Route>
      <Route path="orders/*">
        <Route path="" element={<Orders />} />
        <Route path=":orderId/:waste/:safetyOrder" element={<AddEditOrder />} />
        <Route path="print-order" element={<PrintOrder />} />
        <Route path="account" element={<AccountReport />} />
        <Route path="waste" element={<WasteItem />} />
      </Route>
    </Routes>
  );
};

export default AppPages;
