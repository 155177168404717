import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import DateView from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import Excel from "exceljs";
import { saveAs } from "file-saver";

import { getAccountReport } from "../../../services/order";
import { getStores } from "../../../services/store";
import Loading from "../../../components/ui/Loading";

const workSheetName = 'Sheet1';
const workBookName = 'AccountReport';

const AccountReport = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    const fetchStores = async () => {
      setPageLoading(true);
      try {
        const { data, error } = await getStores();
        if (!error) {
          setStores(data);
        } else {
          alert("show errors");
        }
        setPageLoading(false);
      } catch (err) {
        setPageLoading(false);
      }
    };
    fetchStores();
  }, []);

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const orderStartDate = moment(startDate).format("YYYY-MM-DD");
      const orderEndDate = moment(endDate).format("YYYY-MM-DD");
      const { data, error } = await getAccountReport(
        orderStartDate,
        orderEndDate
      );

      const orders = data.reduce((acc, item) => {
        const key = item.store.code;
        if (!acc[key]) {
          acc[key] = {};
        }
        const dateKey = item.orderDate.replaceAll("-", "");
        if (!acc[key][dateKey]) {
          acc[key][dateKey] = 0;
        }
        acc[key][dateKey] += item.amount;
        return acc;
      }, {});

      if (!error) {
        if (data.length > 0) {
          exportOrder(orders, stores, startDate, endDate);
        } else {
          toast.error("No orders found for this date");
        }
      } else {
        toast.error("Something, went wrong!");
      }
    } catch (err) {
      console.error("Getting error while fetching products");
    }
    setLoading(false);
  };

  const handleExport = () => {
    if (!startDate) {
      toast.error("Please select start date");
      return false;
    }
    if (!endDate) {
      toast.error("Please select end date");
      return false;
    }
    fetchOrders();
  };

  if (pageLoading) {
    return <Loading />;
  }

  return (
    <>
      <Row className="app-layout">
        <Col xs={12} sm={6} md={3}>
          <DateView
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="form-control col-md-2"
            placeholderText="Select Start Date"
            dateFormat="MM/dd/yyyy"
          />
        </Col>
        <Col xs={12} sm={6} md={3}>
          <DateView
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className="form-control col-md-2"
            placeholderText="Select End Date"
            dateFormat="MM/dd/yyyy"
          />
        </Col>
        <Col xs={12} sm={6} md={3}>
          <Button type="button" onClick={handleExport} disabled={loading}>
            {loading ? "Exporting..." : "Export"}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default AccountReport;

const fontSize = 16;

async function exportOrder(orders, stores, startDate, endDate) {
  console.log(orders);
  const columns = [{ header: `Day`, key: "day" }];
  for (const store of stores) {
    columns.push({
      header: store.code,
      key: store.code,
    });
  }

  const d1 = new Date(startDate);
  const d2 = new Date(endDate);

  const dates = getDatesInRange(d1, d2);

  const rows = [];

  for (const date of dates) {
    const row = {
      day: date,
    };
    const dateKey = date.replaceAll("-", "");

    for (const store of stores) {
      const key = `${store.code}`;
      const item = orders[key];
      const rowData = item[dateKey] || 0;
      row[key] = rowData;
    }

    rows.push(row);
  }

  const row = {
    day: "Total",
  };

  for (const store of stores) {
    const key = `${store.code}`;
    const item = orders[key];
    const itemKeys = Object.keys(item);
    const total = itemKeys.reduce((acc, key) => acc + item[key], 0);
    row[key] = total || 0;
  }
  rows.push(row);

  await saveExcel(columns, rows);
}

async function saveExcel(columns, rows) {
  console.log(rows);
  const workbook = new Excel.Workbook();
  try {
    const fileName = workBookName;
    // creating one worksheet in workbook
    const worksheet = workbook.addWorksheet(workSheetName);

    // add worksheet columns
    // each columns contains header and its mapping key from data
    worksheet.columns = columns;

    // updated the font for first row.
    worksheet.getRow(1).font = { bold: true };

    // loop through all of the columns and set the alignment with width.
    worksheet.columns.forEach((column, index) => {
      column.width = column.header.length + (index === 0) ? 15 : 10;
      column.alignment = { horizontal: 'center' };
    });

    // loop through data and add each one to worksheet
    rows.forEach((singleData) => {
      worksheet.addRow(singleData);
    });

    // loop through all of the rows and set the outline style.
    worksheet.eachRow({ includeEmpty: false }, (row) => {
      // store each cell to currentCell
      const currentCell = row._cells;

      // loop through currentCell to apply border only for the non-empty cell of excel
      currentCell.forEach((singleCell, index) => {
        // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
        const cellAddress = singleCell._address;

        // apply border
        worksheet.getCell(cellAddress).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };

        if (index === 0) {
          worksheet.getCell(cellAddress).font = { bold: true };
        }
      });
    });

    // write the content using writeBuffer
    const buf = await workbook.xlsx.writeBuffer();

    // download the processed file
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  } catch (error) {
    console.error('<<<ERRROR>>>', error);
    console.error('Something Went Wrong', error.message);
  } finally {
    // removing worksheet's instance to create new one
    workbook.removeWorksheet(workSheetName);
  }
};

function getDatesInRange(startDate, endDate) {
  const date = new Date(startDate.getTime());

  const dates = [];

  while (date <= endDate) {
    const newDate = new Date(date);
    const month = zeroPad(newDate.getMonth() + 1);
    const day = zeroPad(newDate.getDate());

    const formattedDate = `${newDate.getFullYear()}-${month}-${day}`;
    dates.push(formattedDate);
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

function zeroPad(numberStr) {
  return numberStr.toString().padStart(2, "0");
}
