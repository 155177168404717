import { configureStore } from "@reduxjs/toolkit";
import authSlice from './slices/auth'
import storeSlice from './slices/store'

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        store: storeSlice.reducer
    }
})

export default store