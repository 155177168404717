import React from "react";
import { Container, Row } from "react-bootstrap";
import AuthPages from "../../pages/Auth";

const AuthLayout = () => {
	return (
		<Container
			className='d-flex justify-content-center align-items-center'
			style={{ height: "100vh" }}
		>
			<Row className='d-flex justify-content-center flex-column align-items-center w-100'>
				<AuthPages />
			</Row>
		</Container>
	);
};

export default AuthLayout;
