import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import Logout from './Logout'

const AuthPages = () => {
  return (
	  <Routes>
      <Route path="" element={<Navigate replace to="login" />} />
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="logout" element={<Logout />} />
    </Routes>
  )
}

export default AuthPages