import { createSlice } from "@reduxjs/toolkit";

const USER_STORES = process.env.REACT_APP_USER_STORES;
const initialState = {
    userStores: localStorage.getItem(USER_STORES)
    ? JSON.parse(localStorage.getItem(USER_STORES))
    : null,
};

const store = createSlice({
  name: "store",
  initialState,
  reducers: {
    setUserStores: (state, { payload }) => {
      state.userStores = payload;
    },
  },
});

export const STORE_ACTIONS = store.actions;
export default store;
