import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { BsFillPencilFill, BsChevronDoubleLeft } from "react-icons/bs";

import Loading from "../../../../components/ui/Loading";
import Drawer from "../../../../components/ui/Drawer";
import { getProductItems } from "../../../../services/product-item";

import AddEditProductItem from "./AddEditProductItem";

import AppTable from "../../../../components/ui/Table";
import { useNavigate } from "react-router-dom";

const ProductItems = () => {
  const [productItems, setProductItems] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [productItemId, setProductItemId] = useState("");
  const navigate = useNavigate();

  const columns = [
    {
      label: "Product",
      key: "product",
      content: (item) => <>{item.product.name}</>,
    },
    { path: "name", label: "Name" },
    {
      label: "Unit",
      key: "unit",
      content: (item) => <>{item.unit.name}</>,
    },
    {
      label: "Status",
      key: "active",
      content: (item) => <>{item.active === 1 ? 'Active': 'Inactive'}</>,
    },
    {
      label: "Actions",
      key: "action",
      content: (item) => (
        <Button
          type="button"
          variant="default"
          onClick={() => handleAddEditProductItem(item.id)}
        >
          <BsFillPencilFill />
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const fetchProductItems = async () => {
      try {
        const { data, error } = await getProductItems();
        if (!error) {
          setProductItems(data);
          setPageLoading(false);
        } else {
          alert("show errors");
        }
      } catch (err) {
        console.error("Getting error while fetching products");
      }
    };
    fetchProductItems();
  }, []);

  const handleAddEditProductItem = (id) => {
    setProductItemId(id);
    setShow(true);
  };

  const handleHide = () => {
    setShow(false);
  };

  const handleMoveToProducts = () => {
    navigate('/app/products')
  }

  const handleProductItemUpdate = (data) => {
    setShow(false);
    let availableProductItems = [...productItems];
    const item = availableProductItems.filter((item) => item.id === data.id);
    if (item.length) {
      availableProductItems = availableProductItems.filter((item) => item.id !== data.id);
      setProductItems((prev) => [data, ...availableProductItems]);
    } else {
      setProductItems((prev) => [data, ...prev]);
    }
  };

  if (pageLoading) {
    return <Loading />;
  }

  return (
    <div className="app-layout">
      <div className="d-flex justify-content-between">
        <div className="ms-2 mb-2">
          <h3>Items</h3>
        </div>
        <div className="me-2 mb-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => handleAddEditProductItem(0)}
          >
            Add Item
          </button>
          <button
            type="button"
            className="btn btn-primary ms-2"
            onClick={handleMoveToProducts}
          >
            <BsChevronDoubleLeft /> Products
          </button>
        </div>
      </div>
      <AppTable dataSource={productItems} columns={columns} />
      <Drawer
        show={show}
        onHide={handleHide}
        title={productItemId > 0 ? "Edit Product Item" : "Add Product Item"}
      >
        <AddEditProductItem
          productItemId={productItemId}
          onProductItemUpdate={(data) => handleProductItemUpdate(data)}
          onError={handleHide}
        />
      </Drawer>
    </div>
  );
};

export default ProductItems;
