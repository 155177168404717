import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsFillPencilFill, BsKeyFill } from "react-icons/bs";
import { Button } from "react-bootstrap";
import Loading from "../../../components/ui/Loading";
import Drawer from "../../../components/ui/Drawer";
import { getUsers } from "../../../services/user";
import AddEditUser from "./AddEditUser";

import AppTable from "../../../components/ui/Table";
import { usePermissions } from "../../../hooks/usePermissions";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const userPermission = usePermissions("MANAGE_USER");

  const columns = [
    {
      label: "Name",
      key: "name",
      content: (user) => (
        <>
          {user.firstName} {user.lastName}
        </>
      ),
    },
    { path: "email", label: "Email" },
    {
      label: "Allocation",
      key: "userStores",
      content: (user) => <>{user.stores ? user.stores.length : 0}</>,
    },
    {
      label: "Status",
      key: "active",
      content: (user) => <>{user.active === 1 ? "Active" : "Deactivated"}</>,
    },
    {
      label: "Actions",
      key: "actions",
      content: (user) => (
        <>
          {userPermission.includes("MODIFY") && (
            <>
              <Button
                type="button"
                variant="default"
                onClick={() => handleAddEditUser(user.id)}
                title="Edit"
              >
                <BsFillPencilFill />
              </Button>
              <Button
                type="button"
                variant="default"
                title="Reset Password"
              >
                <a href={user.resetLink} target="_blank">
                  <BsKeyFill />
                </a>
              </Button>
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data, error, messages } = await getUsers();
        if (!error) {
          setUsers(data);
        } else {
          alert("show errors");
        }
      } catch (err) {
        console.error("Getting error while fetching Users");
      }
    };
    fetchUsers();
  }, []);

  const handleAddEditUser = (id) => {
    setUserId(id);
    setShow(true);
  };

  const handleHide = () => {
    setShow(false);
  };

  const handleUserUpdate = (data) => {
    setShow(false);
    let availableUsers = [...users];
    const user = availableUsers.filter((user) => user.id === data.id);
    if (user.length) {
      availableUsers = availableUsers.filter((user) => user.id !== data.id);
      setUsers((prev) => [data, ...availableUsers]);
    } else {
      setUsers((prev) => [data, ...prev]);
    }
  };

  if (!userPermission.includes("VIEW")) {
    navigate("/app/dashboard");
  }

  if (users.length === 0) {
    return <Loading />;
  }

  return (
    <div className="app-layout">
      <div className="d-flex justify-content-between">
        <div className="ms-2 mb-2">
          <h3>Users</h3>
        </div>
        {userPermission.includes("CREATE") && (
          <div className="me-2 mb-2">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleAddEditUser(0)}
            >
              Add Users
            </button>
          </div>
        )}
      </div>
      <AppTable dataSource={users} columns={columns} />
      <Drawer
        show={show}
        onHide={handleHide}
        title={userId > 0 ? "Edit User" : "Add User"}
      >
        <AddEditUser
          userId={userId}
          onUserUpdate={(data) => handleUserUpdate(data)}
          onError={handleHide}
        />
      </Drawer>
    </div>
  );
};

export default Users;
