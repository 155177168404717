import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { BsFillPencilFill } from "react-icons/bs";
import Loading from "../../../components/ui/Loading";
import Drawer from "../../../components/ui/Drawer";
import { getStores } from "../../../services/store";
import AddEditStore from "./AddEditStore";
import { useNavigate } from "react-router-dom";
import AppTable from "../../../components/ui/Table";
import { usePermissions } from "../../../hooks/usePermissions";

const Stores = () => {
  const [stores, setStores] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [storeId, setStoreId] = useState("");
  const navigate = useNavigate();
  const storePermissions = usePermissions("MANAGE_STORE");
  
  const columns = [    
    { path: "priority", label: "Priority" },
    { path: "code", label: "Code" },
    { path: "name", label: "Name" },
    {
      label: "Location",
      key: "address",
      content: (store) => (
        <>
          {store.address.address1} {store.address.address2} <br />
          {store.address.city}, {store.address.state} {store.address.zip}
        </>
      ),
    },
    {
      label: "Actions",
      key: "action",
      content: (store) => {
        return storePermissions.includes('MODIFY') ? 
          (
            <Button
              type="button"
              variant="default"
              onClick={() => handleAddEditStore(store.id)}
            >
              <BsFillPencilFill />
            </Button>
          ) : null;

      },
    },
  ];

  useEffect(() => {
    const fetchStores = async () => {
      setPageLoading(true);
      try {
        const { data, error } = await getStores();
        if (!error) {
          setStores(data);
        } else {
          alert("show errors");
        }
        setPageLoading(false);
      } catch (err) {
        setPageLoading(false);
        console.error("Getting error while fetching stores");
      }
    };
    fetchStores();
  }, []);

  const handleAddEditStore = (id) => {
    setStoreId(id);
    setShow(true);
  };

  const handleHide = () => {
    setShow(false);
  };

  const handleStoreUpdate = (data) => {
    setShow(false);
    let availableStores = [...stores];
    const store = availableStores.filter((store) => store.id === data.id);
    if (store.length) {
      availableStores = availableStores.filter((store) => store.id !== data.id);
      setStores((prev) => [data, ...availableStores]);
    } else {
      setStores((prev) => [data, ...prev]);
    }
  };

  if(!storePermissions.includes('VIEW')) {
    navigate('/app/dashboard');
  }

  if (pageLoading) {
    return <Loading />;
  }

  return (
    <div className="app-layout">
      <div className="d-flex justify-content-between">
        <div className="ms-2 mb-2">
          <h3>Stores</h3>
        </div>
        {storePermissions.includes('CREATE') && (
          <div className="me-2 mb-2">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleAddEditStore(0)}
            >
              Add Store
            </button>
          </div>
        )}
      </div>
      <AppTable dataSource={stores} columns={columns} />
      <Drawer
        show={show}
        onHide={handleHide}
        title={storeId > 0 ? "Edit Store" : "Add Store"}
      >
        <AddEditStore
          storeId={storeId}
          onStoreUpdate={(data) => handleStoreUpdate(data)}
          onError={handleHide}
        />
      </Drawer>
    </div>
  );
};

export default Stores;
