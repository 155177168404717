import { Offcanvas } from "react-bootstrap";

const Drawer = ({ title, show, onHide, children }) => {
	if (!show) return null;
	return (
		<Offcanvas show={show} onHide={onHide} placement={'end'} backdrop={true} className="w-50">
			<Offcanvas.Header closeButton>
				<Offcanvas.Title>{title}</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>{children}</Offcanvas.Body>
		</Offcanvas>
	);
};

export default Drawer;
