import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import FormikControl from "../../../components/formik/FormikControl";
import { addUser, editUser, getUserById } from "../../../services/user";
import { getStores } from "../../../services/store";

const initialValues = {
  id: 0,
  firstName: "",
  middleName: "",
  lastName: "",
  active: true,
  userStores: [],
  userRoles: ''
};

const validationSchema = Yup.object({
  firstName: Yup.string().required("Firstname required!"),
  lastName: Yup.string().required("Lastname required!"),
  email: Yup.string().email().required("Email Required!"),
  active: Yup.boolean(),
  userStores: Yup.array().min(1, 'Store selection Required!'),
  userRoles: Yup.string().required("Role selection Required!")
});

const AddEditUser = ({ userId, onUserUpdate, onError }) => {
  const [stores, setStores] = useState([]);
  const [user, setUser] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      const { data, error } = await getUserById(userId);
      if (error) {
        toast.error("Error, while loading User! Please contact Web Admin!");
      } else {
        data.userStores = data.stores ? data.stores.map(store => store.id) : [];
        data.userRoles = data.roles ? data.roles.map(item => item.id.toString())[0] : '';
        data.active = data["active"] === 1 ? true : false
        setUser(data);
      }
    };
    if (userId > 0) fetchUser();
  }, [userId]);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const { data, error } = await getStores();
        if (!error) {
          setStores(
            data.map((store) => ({ key: store.name, value: store.id }))
          );
        } else {
          alert("show errors");
        }
      } catch (err) {
        console.error("Getting error while fetching stores");
      }
    };
    fetchStores();
  }, []);

  const onSubmit = (values) => {
    values.active = values.active ? 1 : 0;

    if (userId === 0) {
      handleAddUser(values);
      delete values.id;
    } else {
      handleEditUser(values);
    }
  };

  const handleAddUser = async (values) => {
    delete values.id;
    values.stores = values.userStores.map(store => ({id: +store}));
    values.roles = [{id: +values.userRoles}];
    delete values.userStores;
    delete values.userRoles;
    delete values.created;
    delete values.modify;
    delete values.type;
    setLoading(true);
    try {
      const { data, error, messages } = await addUser(values);
      if (!error) {
        // success
        toast.success(`User created successfully!`);
        onUserUpdate(data);
        setLoading(false);
      } else {
        toast.error(messages.join(","));
        onError();
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleEditUser = async (values) => {
    delete values.id;
    values.stores = values.userStores.map(store => ({id: +store}));
    values.roles = [{id: +values.userRoles}];
    delete values.userStores;
    delete values.userRoles;
    delete values.created;
    delete values.modify;
    delete values.type;
    setLoading(true);
    try {
      const { data, error, messages } = await editUser(userId, values);
      if (!error) {
        // success
        toast.success(`User updated successfully!`);
        onUserUpdate(data);
        setLoading(false);
      } else {
        toast.error(messages.join(","));
        onError();
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={user}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <>
            <Form autoComplete="off">
              <FormikControl
                control="input"
                type="text"
                name="firstName"
                label="Firstname"
                placeholder="Enter Firstname"
              />
              <FormikControl
                control="input"
                type="text"
                name="lastName"
                label="Lastname"
                placeholder="Enter Lastname"
              />
              <FormikControl
                control="input"
                type="email"
                name="email"
                label="Email"
                placeholder="Enter email"
              />              
              <FormikControl
                control="select"
                name="userRoles"
                label="Role"
                options={[
                  { key: "-Select Role-", value: "" },
                  { key: "Administrator", value: "1" },
                  { key: "User", value: "2" },
                ]}               
              />
              <FormikControl
                control="select"
                name="userStores"
                label="Stores"
                options={stores}
                multiple="multiple"
              />
              
              <FormikControl
                control="singleCheckBox"
                name="active"
                label="Active?"
              />

              <div className="d-flex justify-content-between">
                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? "Please wait..." : "Submit"}
                </Button>
              </div>
            </Form>
          </>          
        )}
      </Formik>
    </>
  );
};

export default AddEditUser;