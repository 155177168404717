import React from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";

import AppPages from "../../pages/App";
import Header from "../../components/app/Header";
import { UserProvider } from "../../store/context/User";
import Logout from "../../pages/Auth/Logout";

const AppLayout = () => {
	const { token } = useSelector((state) => state.auth);

	if (!token) {
		return <Logout />;
	}

	return (
		<Container fluid>
			<UserProvider>
				<Header />
				<Container className='mt-3'>
					<AppPages />
				</Container>
			</UserProvider>
		</Container>
	);
};

export default AppLayout;
