export const states = [{value: 'AL', key: 'Alabama'},
{value: 'AK', key: 'Alaska'},
{value: 'AZ', key: 'Arizona'},
{value: 'AR', key: 'Arkansas'},
{value: 'CA', key: 'California'},
{value: 'CO', key: 'Colorado'},
{value: 'CT', key: 'Connecticut'},
{value: 'DC', key: 'District of Columbia'},
{value: 'DE', key: 'Delaware'},
{value: 'FL', key: 'Florida'},
{value: 'GA', key: 'Georgia'},
{value: 'HI', key: 'Hawaii'},
{value: 'ID', key: 'Idaho'},
{value: 'IL', key: 'Illinois'},
{value: 'IN', key: 'Indiana'},
{value: 'IA', key: 'Iowa'},
{value: 'KS', key: 'Kansas'},
{value: 'KY', key: 'Kentucky'},
{value: 'LA', key: 'Louisiana'},
{value: 'ME', key: 'Maine'},
{value: 'MA', key: 'Massachusetts'},
{value: 'MD', key: 'Maryland'},
{value: 'MI', key: 'Michigan'},
{value: 'MN', key: 'Minnesota'},
{value: 'MS', key: 'Mississippi'},
{value: 'MO', key: 'Missouri'},
{value: 'MT', key: 'Montana'},
{value: 'NE', key: 'Nebraska'},
{value: 'NV', key: 'Nevada'},
{value: 'NH', key: 'New Hampshire'},
{value: 'NJ', key: 'New Jersey'},
{value: 'NM', key: 'New Mexico'},
{value: 'NY', key: 'New York'},
{value: 'NC', key: 'North Carolina'},
{value: 'ND', key: 'North Dakota'},
{value: 'OH', key: 'Ohio'},
{value: 'OK', key: 'Oklahoma'},
{value: 'OR', key: 'Oregon'},
{value: 'PA', key: 'Pennsylvania'},
{value: 'PR', key: 'Puerto Rico'},
{value: 'RI', key: 'Rhode Island'},
{value: 'SC', key: 'South Carolina'},
{value: 'SD', key: 'South Dakota'},
{value: 'TN', key: 'Tennessee'},
{value: 'TX', key: 'Texas'},
{value: 'UT', key: 'Utah'},
{value: 'VT', key: 'Vermont'},
{value: 'VA', key: 'Virginia'},
{value: 'WA', key: 'Washington'},
{value: 'WV', key: 'West Virginia'},
{value: 'WI', key: 'Wisconsin'},
{value: 'WY', key: 'Wyoming'},
{value: 'VI', key: 'Virgin Islands'},
{value: 'AS', key: 'American Samoa'},
{value: 'FM', key: 'Federated States of Micronesia'},
{value: 'GU', key: 'Guam'},
{value: 'MH', key: 'Marshall Islands'},
{value: 'MP', key: 'Northern Mariana Islands'},
{value: 'PW', key: 'Palau'},
{value: 'AA', key: 'Military'},
{value: 'UM', key: 'U.S. Minor Outlying Islands'}];