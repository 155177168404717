import { ErrorMessage, Field } from "formik"
import { FormGroup, FormLabel } from "react-bootstrap";
import TextError from './TextError'

const TextInput = (props) => {
	const {label, name, ...rest} = props
	return (
		<FormGroup className="mb-3">
			<FormLabel>{label}</FormLabel>
			<Field id={name} name={name} {...rest} className="form-control" />
			<ErrorMessage name={name} component={TextError} />
		</FormGroup>
	)
};

export default TextInput;
