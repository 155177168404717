import React, { useState } from "react";
import { Button, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import FormikControl from "../../../components/formik/FormikControl";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "../../../services/auth";
import { toast } from "react-toastify";

const initialValues = {
  password: "",
  passwordConfirm: "",
};

const validationSchema = Yup.object({
  password: Yup.string().required("Password is required"),
  passwordConfirm: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const onSubmit = async (values) => {
    setLoading(true);
    delete values.passwordConfirm;
    values.token = token;
    try {
      const { messages } = await resetPassword(values);
      toast.info(messages[0]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleSingIn = () => {
    navigate("/auth/login");
  };

  return (
    <>
      <Col className="text-center mb-3">
        <h2>Reset Password</h2>
      </Col>
      <Col
        xs={12}
        sm={10}
        md={8}
        lg={6}
        xl={4}
        xxl={4}
        className="border p-3 rounded app-layout"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <>
              <Form autoComplete="off">
                <FormikControl
                  control="input"
                  type="password"
                  name="password"
                  label="Password"
                  placeholder="Enter Password"
                />
                <FormikControl
                  control="input"
                  type="password"
                  name="passwordConfirm"
                  label="Confirm Password"
                  placeholder="Reenter Password"
                />

                <div className="d-flex justify-content-between">
                  <Button variant="primary" type="submit" disabled={loading}>
                    {loading ? "Please wait..." : "Submit"}
                  </Button>
                  <Button
                    variant="default"
                    type="button"
                    onClick={handleSingIn}
                  >
                    Back to Sing In
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </Col>
    </>
  );
};

export default ResetPassword;
